*,
*::before,
*::after {
    box-sizing: border-box;
    user-select: none;
}

body {
    font-family: 'Poppins', sans-serif;
}

body, h1, h2, p {
    margin: 0;
}

button {
    background-color: transparent;
}

button:hover {
    cursor: pointer;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.App {
    min-height: 100vh;
    display: grid;
    grid-template-rows: 1fr auto;
    background-image: url('./assets/map.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    gap: .5rem;
    padding: 1rem;
}

.UpperSection {
    display: grid;
    grid-template-columns: 1fr 8fr;
    gap: 1rem;
}

.TraitsContainer,
.GameFormContainer {
    display: grid;
    place-content: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.LowerSection {
    display: grid;
    grid-template-rows: 1fr auto 2fr;
    row-gap: .2rem;
    padding: 0 7%;
}

.BenchContainer,
.DashboardContainer,
.ShopContainer {
    display: grid;
    place-content: center;
    grid-template-rows: 1fr;
}

.BenchContainer {
    grid-template-columns: 1fr;
    text-align: center;
}

.DashboardContainer {
    grid-template-columns: 1fr 1.5fr 1fr 2.5fr;
    text-align: center;
    gap: .2rem;
}

.ShopContainer {
    grid-template-columns: 1fr 5fr;
    gap: .2rem;
    height: 100%;
}

.RightShop {
    position: relative;
}