.Instructions {
    z-index: 2;
    position: relative;
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 1rem;
}

.Instructions_header {
    font-size: 2rem;
    text-align: center;
}

.Instructions_list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.2rem;
    justify-self: center;
}

.Instructions_listitemIndented {
    padding-left: 2rem;
}

.Instructions_link {
    color: #FFFFFF;
}

.Instructions_link:hover {
    color: #0000FF;
}

@media screen and (max-width: 1600px) {
    
.Instructions_header {
    font-size: 1.5rem;
}

.Instructions_list {
    font-size: 1rem;
}

}

@media screen and (max-height: 850px) {
    .Instructions {
        gap: 0.5rem;
    }
    
    .Instructions_header {
        font-size: 1rem;
    }
    
    .Instructions_list {
        font-size: 0.8rem;
        gap: 0.5rem;
    }
    
}
    