.GameHistory {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-rows: auto repeat(4, 1fr);
    gap: 1rem;
}

.GameHistory_tableHeaders {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    justify-items: center;
    align-items: center;
    text-align: center;
}
