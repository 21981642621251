.Percentages {
    display: flex;
    justify-content: space-around;
    background-color: #181c26;
    border: solid 1px #2a5862;
    padding: .5rem;
}

.Percentages > p:nth-child(1) {
    color: lightgrey
}


.Percentages > p:nth-child(2) {
    color: green
}


.Percentages > p:nth-child(3) {
    color: lightskyblue
}


.Percentages > p:nth-child(4) {
    color: magenta
}


.Percentages > p:nth-child(5) {
    color: orange
}