.SellArea {
    display: grid;
    place-content: center;
    border: solid 1px #181c26;
    background-color: #2a5862;
    color: #FFFFFF;
    font-size: 1.5rem;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.SellArea:hover {
    opacity: .5;
}