.Traits {
    text-align: center;
    display: grid;
    grid-template-rows: auto 1fr;
    gap: .5rem;
    color: #FFFFFF;
    background-color: #2a5862e0;
    border: solid 1px #181c26;
    padding-top: .5rem;
    overflow: hidden;
}

.Traits_header {
    font-size: 1.2rem;
}

.Traits_traitsContainer {
    display: flex;
    flex-direction: column;
    gap: .2rem;
}

.Traits_trait {
    display: grid;
}

@media screen and (max-width: 1600px) {
    .Traits_header {
        font-size: 1rem;
    }

    .Traits_trait {
        font-size: 0.8rem;
    }
    
}

@media screen and (max-height: 850px) {
    
    
}