.ShopButtons {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: .2rem  
}

.ShopButtons_buyXPContainer,
.ShopButtons_refreshContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-content: center;
    color: #FFFFFF;
    place-items: center;
    background-color: #181c26;
    border: solid 3px #2a5862;
}

.ShopButtons_buyXPContainer:hover,
.ShopButtons_refreshContainer:hover {
    cursor: pointer;
    background-color: #2a5862;
}